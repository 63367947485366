import { staticPath_logo_svg } from "lib/$asset-path"
import { BoxProps } from "@chakra-ui/react"
import {
  Box,
  chakra,
  Flex,
  Link,
  Stack,
  StackProps,
  VisuallyHidden,
} from "@chakra-ui/react"
import { COMPANY } from "@onemile/corporate-data"
import { NextImage } from "components/system"
import { harada, OmpMember } from "data/omp-members"

import React, { PropsWithChildren } from "react"

export const Corporate: React.FC<StackProps> = (props) => {
  const executives = [harada]
  return (
    <Flex
      spacing={{ base: 6, md: 10 }}
      direction={"column"}
      align={"center"}
      {...props}
    >
      <chakra.h3
        textAlign="center"
        maxW={{
          base: 160,
          md: 270,
        }}
      >
        <VisuallyHidden>Monicle Financial Inc.</VisuallyHidden>
        <NextImage
          width={270}
          height={83}
          unoptimized
          src={staticPath_logo_svg}
          alt="Monicle Financial Inc."
          layout="intrinsic"
        />
      </chakra.h3>
      <CorporateAbout
        mt={{ base: 6, md: 10 }}
        mx={{ base: 3, md: 0 }}
        executives={executives}
      />
    </Flex>
  )
}

const CorporateAbout = ({
  executives,
  ...props
}: {
  executives: OmpMember[]
} & BoxProps) => (
  <Box
    layerStyle="passive"
    py={{ base: 4, md: 8 }}
    px={3}
    pl={{ md: "120px" }}
    textStyle="f14"
    color="text.trueBlack"
    {...props}
  >
    <Stack spacing={4}>
      <CorporateItem title="会社名">
        株式会社モニクルフィナンシャル
      </CorporateItem>
      <CorporateItem title="代表者">
        {executives.map((e, idx) => (
          <Flex direction={{ base: "column", md: "row" }} key={idx}>
            <chakra.p mr={2}>
              {e.position} {e.name}
            </chakra.p>
          </Flex>
        ))}
      </CorporateItem>
      <CorporateItem title="資本金（資本準備金含む）">
        {COMPANY.capital_amount_yen}
      </CorporateItem>
      <CorporateItem title="所在地">
        <Stack spacing={"1em"}>
          <chakra.p>
            本社 <br />
            {COMPANY.offices.headquarters.address.join(" ")}
          </chakra.p>
          {COMPANY.offices.stores.map((store) => (
            <chakra.p key={store.key}>
              {store.name}
              <br />
              {store.address.join(" ")}
            </chakra.p>
          ))}
        </Stack>
      </CorporateItem>
      <CorporateItem title="登録番号">{COMPANY.fsa_number}</CorporateItem>
      <CorporateItem title="公式サイト">
        <Link isExternal href="https://moniclefinancial.co.jp">
          https://moniclefinancial.co.jp
        </Link>
      </CorporateItem>
    </Stack>
  </Box>
)

const CorporateItem = ({
  title,
  children,
}: PropsWithChildren<{ title: string }>) => (
  <Flex direction={{ base: "column", md: "row" }}>
    <chakra.strong w="200px" textStyle="f14Bold">
      {title}
    </chakra.strong>
    <chakra.div>{children}</chakra.div>
  </Flex>
)
