import { Heading, HeadingProps } from "components/system"
import React, { ReactElement } from "react"

export type SectionTitleProps = HeadingProps & {
  titleLabel: ReactElement | string
}

export const SectionTitle: React.FC<SectionTitleProps> = ({
  titleLabel: title,
  ...props
}) => (
  <Heading as="h2" textStyle="h3" textAlign="center" mb={5} {...props}>
    {title}
  </Heading>
)
