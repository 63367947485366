import { ChakraProps, Flex, FlexProps } from "@chakra-ui/react"
import { Footer, Header } from "@~/common/components"
import React, { PropsWithChildren } from "react"
import LazyHydrate from "react-lazy-hydration"

export type AppPageLayoutProps = PropsWithChildren<{
  /** ヘッダー
   * - useHeaderHeightで現在のヘッダーの高さを出力すること
   */
  header?: React.ComponentType<ChakraProps> | React.ReactNode | null
  footer?: React.ComponentType<ChakraProps> | React.ReactNode | null
  _content?: FlexProps
}>

export const AppPageLayout = ({
  header: HeaderComponent = Header,
  footer: FooterComponent = Footer,
  _content,
  ...props
}: AppPageLayoutProps) => {
  const header = isReactComponent(HeaderComponent) ? (
    <HeaderComponent zIndex={2} />
  ) : (
    HeaderComponent
  )
  const footer = isReactComponent(FooterComponent) ? (
    <FooterComponent />
  ) : (
    FooterComponent
  )
  return (
    <Flex minH="100vh" minW="100%" direction="column" pos="relative" zIndex={1}>
      {header}
      <Flex minH="md" direction="column" flexGrow={1} {..._content}>
        {props.children}
      </Flex>
      {footer && (
        <LazyHydrate whenIdle>
          <>{footer}</>
        </LazyHydrate>
      )}
    </Flex>
  )
}

const isReactComponent = (c: any): c is React.ComponentType<ChakraProps> =>
  typeof c === "function"

export const createGetAppPageLayout = (
  layoutProps?: AppPageLayoutProps
): ((page: React.ReactElement) => React.ReactNode) => {
  return function getLayout(page: React.ReactElement): React.ReactNode {
    return <AppPageLayout {...layoutProps}>{page}</AppPageLayout>
  }
}

export function getAppPageLayout(page: React.ReactElement): React.ReactNode {
  return <AppPageLayout>{page}</AppPageLayout>
}
