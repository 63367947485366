import {
  staticPath_assets_affiliate_modal_moneiroconcierge_svg,
  staticPath_assets_affiliate_modal_seminar_svg,
  staticPath_assets_affiliate_modal_shindan_svg,
  staticPath_assets_illusts_woman_concierge_wc_basic_svg,
} from "lib/$asset-path"
import { ChevronRightIcon } from "@chakra-ui/icons"
import {
  Box,
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  chakra,
} from "@chakra-ui/react"
import { Logo } from "@~/common/components"
import { Visible } from "components/system"
import NextImage from "next/legacy/image"

export const AffiliateModalV1 = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        bgColor="moneiro-color-main-orange"
        w={{ base: "340px", md: "430px" }}
      >
        <ModalBody
          bgColor={"white"}
          m={{ base: 2, md: 3 }}
          rounded="m"
          p={3}
          pos="relative"
        >
          <Visible baseOnly>
            <Box pos="absolute" top="-4px" left="6px">
              <NextImage
                src={staticPath_assets_illusts_woman_concierge_wc_basic_svg}
                width={53}
                height={89}
                alt=""
                unoptimized
              />
            </Box>
          </Visible>
          <Visible mdOnly>
            <Box pos="absolute" top="-10px" left="-5px">
              <NextImage
                src={staticPath_assets_illusts_woman_concierge_wc_basic_svg}
                width={69}
                height={116}
                alt=""
                unoptimized
              />
            </Box>
          </Visible>
          <Flex direction={"column"} alignItems="center">
            <Logo
              title="マネイロ"
              w={{ base: "91px", md: "115px" }}
              h={{ base: "26px", md: "33px" }}
            />
            <chakra.h1
              textStyle={{ base: "18-bold", md: "24-bold" }}
              color="moneiro-color-main-orange"
              mt={{ base: 2, md: 3 }}
            >
              公式サイトへようこそ
            </chakra.h1>
            <chakra.p
              textStyle={{ base: "14-reg", md: "16-reg" }}
              fontWeight="500"
              mt={{ base: "17px", md: 3 }}
            >
              直前にご覧いただいたサイトの情報に関しては、各サイトが作成管理する感想や評価です。その内容を当社が保証するものではありませんので、予めご了承ください。
            </chakra.p>
            <Visible baseOnly>
              <HStack spacing={3} w="full" mt="14px">
                {affiliateImageData.map((data, idx) => (
                  <Box key={idx}>
                    <NextImage src={data} alt="" width="83" height="83" />
                  </Box>
                ))}
              </HStack>
            </Visible>
            <Visible mdOnly>
              <HStack spacing={4} w="full" mt={4}>
                {affiliateImageData.map((data, idx) => (
                  <Box key={idx}>
                    <NextImage src={data} alt="" width="93" height="93" />
                  </Box>
                ))}
              </HStack>
            </Visible>
            <Button
              colorScheme="secondary"
              size="lg"
              w="full"
              justifyContent="space-between"
              leftIcon={<Box boxSize={{ base: "20px", md: "24px" }} />}
              rightIcon={
                <ChevronRightIcon boxSize={{ base: "20px", md: "24px" }} />
              }
              boxShadow="btn"
              fontSize={{ base: "13px", md: "16px" }}
              onClick={onClose}
              mt={4}
            >
              マネイロ公式サイトへ進む
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

const affiliateImageData = [
  staticPath_assets_affiliate_modal_seminar_svg,
  staticPath_assets_affiliate_modal_shindan_svg,
  staticPath_assets_affiliate_modal_moneiroconcierge_svg,
]
