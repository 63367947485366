import { useDisclosure } from "@chakra-ui/react"
import { UnreachableCaseError } from "@~/common/lib/error"
import { useRouter } from "next/router"
import { useEffect } from "react"
import { AffiliateModalV1 } from "./AffiliateModalV1"

type PpupQuery = string | undefined
const versions = ["1"] as const
type PpupVersion = typeof versions[number]
type UseAffiliateModal = () => JSX.Element

/**
 * アフィリエイト広告を出稿するにあたり、
 * アフィリエイターのコンテンツと、マネイロの公式コンテンツの区別が明確につくようにするための
 * ポップアップ(ppup)
 * @see https://onemile.atlassian.net/browse/MN-486?atlOrigin=eyJpIjoiZDhlMjZkMjcyYjdjNDIwNDg3NWI4MmE0YTg4Y2RjNjAiLCJwIjoiaiJ9
 */
export const useAffiliateModal = (): UseAffiliateModal => {
  const router = useRouter()
  const ppupQuery = router.query.ppup as PpupQuery
  const ppupVersion: PpupVersion | undefined = versions.find(
    (v) => v === ppupQuery
  )
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    if (!ppupVersion) return

    onOpen()
    const id = setTimeout(onClose, 5000)

    return () => {
      id && clearTimeout(id)
    }
  }, [onClose, onOpen, ppupVersion])

  const renderAffiliateModal = () => {
    if (!ppupVersion) return <></>

    switch (ppupVersion) {
      case "1": {
        return <AffiliateModalV1 isOpen={isOpen} onClose={onClose} />
      }
      default: {
        const invalidVersion: never = ppupVersion
        throw new UnreachableCaseError(
          invalidVersion,
          `不明なppupのバージョンです`
        )
      }
    }
  }
  return renderAffiliateModal
}
