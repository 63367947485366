import {
  staticPath_assets_lp_common_taikensya_badge_png,
  staticPath_assets_lp_common_manzokudo_badge_png,
} from "lib/$asset-path"
import {
  Center,
  CenterProps,
  chakra,
  Flex,
  HStack,
  HTMLChakraProps,
  Img,
} from "@chakra-ui/react"
import { Break } from "components/system"

import Head from "next/head"

export type MainVisualAppealProps = {
  /**
   * 体験者数バッジに加えて満足度バッジも表示するならtrueを指定する
   */
  twoBadges?: boolean
  _annotation?: HTMLChakraProps<"div">
} & CenterProps

const Taikensya = () => {
  const src = staticPath_assets_lp_common_taikensya_badge_png

  return (
    <>
      <Head>
        <link href={src} rel="preload" as="image" />
      </Head>
      <Img
        src={src}
        w={123.5}
        h={58}
        htmlWidth={123.5}
        htmlHeight={58}
        alt="体験者数15万人突破"
      />
    </>
  )
}

const Manzoku = () => {
  const src = staticPath_assets_lp_common_manzokudo_badge_png

  return (
    <>
      <Head>
        <link href={src} rel="preload" as="image" />
      </Head>
      <Img
        src={src}
        w={146.5}
        h={58.5}
        htmlWidth={146.5}
        htmlHeight={58.5}
        alt="満足度98.6%"
      />
    </>
  )
}

export const MainVisualAppeal = ({
  twoBadges = false,
  _annotation,
  ...props
}: MainVisualAppealProps) => {
  const updateAt = "2024年5月時点"

  if (!twoBadges) {
    return (
      <Center
        pb={{ base: 2, md: 4 }}
        pt={{ base: 3, md: 4 }}
        bgColor="moneiro-color-white"
        {...props}
      >
        <HStack spacing={2}>
          <Taikensya />
          <chakra.div
            textStyle="10-reg"
            color="moneiro-color-main-orange"
            lineHeight={{ base: "160%", md: undefined }}
            {..._annotation}
          >
            ※診断・勉強・相談サービスの
            <Break baseOnly />
            累計利用者数/{updateAt}
          </chakra.div>
        </HStack>
      </Center>
    )
  } else {
    return (
      <Flex
        direction="column"
        alignItems="center"
        pb={{ base: 2, md: 0 }}
        pt={{ base: 3, md: 4 }}
        bgColor="moneiro-color-white"
        {...props}
      >
        <HStack spacing={4} px={3}>
          <Manzoku />
          <Taikensya />
        </HStack>
        <chakra.div
          textStyle="10-reg"
          color="moneiro-color-main-orange"
          lineHeight={{ base: "160%", md: undefined }}
          mt={2}
          {..._annotation}
        >
          ※診断・勉強・相談サービスの累計利用者数/{updateAt}
        </chakra.div>
      </Flex>
    )
  }
}
