import { Box, BoxProps } from "@chakra-ui/react"
import { SectionTitle, SectionTitleProps } from "components/common-ui"
import React from "react"

export type LayerProps = React.PropsWithChildren<
  Partial<Pick<SectionTitleProps, "titleLabel">> & {
    /** タイトルがある時はSectionTitleに付与されます */
    id?: string
    isFullWidth?: boolean
    maxW?: string
    _titleLabelProps?: Partial<Omit<SectionTitleProps, "titleLabel">>
    variant?: "condenced"
  } & BoxProps
>

/** 各セクションのタイトルとコンテンツ */
export const Layer = ({
  titleLabel,
  id,
  isFullWidth = false,
  maxW = "container.secondContent",
  width = "full",
  children,
  _titleLabelProps,
  variant,
  ...props
}: LayerProps) => (
  <Box
    id={!titleLabel ? id : undefined}
    px={isFullWidth ? 0 : 3}
    py={props.py ?? variant === "condenced" ? 0 : 6}
    as="section"
    width={"full"}
    {...props}
  >
    <Box maxW={maxW} mx={{ md: "auto" }}>
      {titleLabel && (
        <SectionTitle
          id={id}
          titleLabel={titleLabel}
          fontWeight={
            variant === "condenced" ? { base: "bold", md: "normal" } : undefined
          }
          {..._titleLabelProps}
        />
      )}
      {children}
    </Box>
  </Box>
)
