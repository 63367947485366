import { Box, forwardRef, HeadingProps } from "@chakra-ui/react"
import React from "react"
export type { HeadingProps } from "@chakra-ui/react"

/** `@chakra-ui/react.Heading`と同等のコンポーネント
 * textStyleが使用できないためこちらを使用してください。
 * @see https://github.com/chakra-ui/chakra-ui/issues/3501
 *
 * NOTE: chakra factoryでもうまくいかなかった
 */
export const Heading = forwardRef<HeadingProps, "h2">((props, ref) => {
  return <Box ref={ref} {...props} />
})

Heading.defaultProps = { as: "h2", textStyle: "_headings" }
