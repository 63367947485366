import { chakra, HStack, Stack, StackProps } from "@chakra-ui/react"
import { NextImage } from "components/system"
import { OmpMember } from "data/omp-members"
import React from "react"

export type SupervisorProps = {
  supervisor: OmpMember
  /** 何の監修者かのテキスト ex)コンテンツ監修者 */
  context: string
} & StackProps

export const Supervisor = ({
  supervisor,
  context,
  ...props
}: SupervisorProps) => {
  return (
    <Stack
      spacing={{ base: 1, md: 6 }}
      direction={{ base: "column", md: "row" }}
      align="flex-start"
      w="full"
      maxW="container.content"
      {...props}
    >
      <HStack spacing={2} flexShrink={0}>
        <NextImage
          src={supervisor.avatarPath}
          width={58}
          height={58}
          alt={`${supervisor.name}のアバター画像`}
          objectFit="cover"
          objectPosition="top"
          borderRadius="full"
        />
        <Stack spacing={1}>
          <chakra.div textStyle="f14Bold">
            {context}：{supervisor.name}
          </chakra.div>
          <chakra.div textStyle="f11">
            {supervisor.positions?.join("/")}
          </chakra.div>
        </Stack>
      </HStack>
      <chakra.p textStyle="f11">{supervisor.profile}</chakra.p>
    </Stack>
  )
}
