import React from "react"
import { BoxProps, chakra, Text } from "@chakra-ui/react"

/** テキスト中のスタイルを部分的に変更するためのコンポーネント
 *
 * - デフォルトでspanで描画されます。
 * - フォントサイズとフォント指定はデフォルトでinherit
 */
export const Span: React.FC<BoxProps> = (props) => (
  <Text
    as="span"
    {...props}
    fontSize={props.fontSize ?? "inherit"}
    fontFamily={props.fontFamily ?? "inherit"}
  />
)

/** <Wbr>自動で</Wbr>
 * <Wbr>改行される場合、</Wbr>
 * <Wbr>このコンポーネントで囲った</Wbr>
 * 要素はで
 * きるだ
 * け改行さ
 * れなくな
 * ります。 */
export const Wbr: React.FC<
  BoxProps & {
    /** このコンポーネントの中でもさらに改行したくない場合 */
    nowrap?: boolean
  }
> = ({ nowrap, ...props }) => (
  <chakra.span
    display="inline-block"
    whiteSpace={nowrap ? "nowrap" : undefined}
    {...props}
  />
)

const ChakraBr = chakra("br")

/** brタグをbase/mdどちらかでのみ使用できます。
 * 基本的にはWbrで動的に区切りますが、こちらも使用可能です。
 */
export const Break: React.FC<
  | {
      mdOnly: true
      baseOnly?: false
    }
  | {
      mdOnly?: false
      baseOnly: true
    }
> = (props) => (
  <ChakraBr
    display={props.mdOnly ? { base: "none", md: "initial" } : { md: "none" }}
  />
)
