import {
  staticPath_assets_advisers_izumida_jpg,
  staticPath_assets_omp_members_harada_png,
  staticPath_assets_omp_members_kamura_png,
  staticPath_assets_omp_members_kamura_profile_jpg,
  staticPath_assets_omp_members_miyazawa_png,
  staticPath_assets_omp_members_nishimori_jpg,
  staticPath_assets_omp_members_oono_png,
  staticPath_assets_omp_members_sato_jpg,
  staticPath_assets_omp_members_shiozawa_jpg,
  staticPath_assets_omp_members_takahashi_jpg,
  staticPath_assets_omp_members_takahashi_profile_jpg,
  staticPath_assets_omp_members_ytanaka_png,
} from "lib/$asset-path"

export type OmpMember = {
  /** ADMIN 側の SeminarTopic#speaker_key と同じ値にすることでセミナーとスピーカーをマッピングする */
  key: string
  avatarPath: string
  imagePath?: string
  name: string
  position: string // NOTE:セミナーLP内に表示する用途
  positions: string[] // NOTE: セミナーLP内に表示する用途
  profile: string
  role: OmpRole[] // NOTE: セミナーTOPでの一覧表示振り分け用途
}

export type OmpRole = "moneySeminarSpeaker"

export const harada: OmpMember = {
  key: "harada",
  avatarPath: staticPath_assets_omp_members_harada_png,
  name: "原田 慎司",
  position: "代表取締役CEO",
  positions: ["代表取締役CEO"],
  profile:
    "山口県出身。一橋大学卒業後、大和総研、三菱UFJモルガン・スタンレー証券、ドイツ証券およびシティグループ証券に証券アナリストおよびM&Aバンカーとして勤務。シティグループ証券では総合電機業界の調査責任者を務める。2013年に株式会社ナビゲータープラットフォーム（現：株式会社モニクルリサーチ）を共同創業し、お金をテーマにしたメディアの急成長を成し遂げる。",
  role: [],
}

export const izumida: OmpMember = {
  key: "izumida",
  avatarPath: staticPath_assets_advisers_izumida_jpg,
  name: "泉田 良輔",
  position: "",
  positions: ["証券アナリスト", "元機関投資家"],
  profile:
    "慶應義塾大学商学部卒。東京工業大学大学院非常勤講師。日本証券アナリスト協会認定アナリスト（CMA）。日本生命やフィデリティ投信で外国株式や日本株式運用のファンドマネージャーや証券アナリストとして従事。Amazon「一般・投資読み物」カテゴリで第1位を記録した「機関投資家だけが知っている『予想』のいらない株式投資」など著書多数。2018年にマネイロを運営するOneMile Partners（現：株式会社モニクルフィナンシャル）を創業。",
  role: ["moneySeminarSpeaker"],
}

export const kamura: OmpMember = {
  key: "kamura",
  avatarPath: staticPath_assets_omp_members_kamura_png,
  imagePath: staticPath_assets_omp_members_kamura_profile_jpg,
  name: "嘉村 有衣子",
  position: "ファイナンシャルアドバイザー",
  positions: ["マネイロコンシェル", "ファイナンシャルアドバイザー"],
  profile:
    "全労済や国内大手保険代理店を経て、現在マネイロコンシェルとして生命保険や投資信託を活用した資産運用アドバイスを行う。顧客満足度が非常に高く、お客様に寄り添ったアドバイスに定評。一種外務員資格保有。",
  role: ["moneySeminarSpeaker"],
}

export const SATO: OmpMember = {
  key: "sato",
  avatarPath: staticPath_assets_omp_members_sato_jpg,
  name: "佐藤 雄基",
  position: "ファイナンシャルアドバイザー",
  positions: ["ファイナンシャルアドバイザー"],
  profile:
    "国内大手証券会社で資産運用コンサルティング業務に約11年間従事。国内外株式、債券、投資信託、保険に詳しい。現在はマネイロコンシェルとして資産運用をサポート。AFP、一種外務員資格保有。",
  role: [],
}

export const miyazawa: OmpMember = {
  key: "miyazawa",
  avatarPath: staticPath_assets_omp_members_miyazawa_png,
  name: "宮澤 顕介",
  position: "ファイナンシャルアドバイザー",
  positions: ["ファイナンシャルアドバイザー"],
  profile:
    "明治大学卒。大手金融機関を経て、2019年よりマネイロコンシェルとして資産運用アドバイスに従事。マネーセミナーでも登壇多数。2級ファイナンシャル・プランニング技能士(FP2級)、証券外務員一種資格を保有。",
  role: ["moneySeminarSpeaker"],
}

export const takahashi: OmpMember = {
  key: "takahashi",
  avatarPath: staticPath_assets_omp_members_takahashi_jpg,
  imagePath: staticPath_assets_omp_members_takahashi_profile_jpg,
  name: "高橋 明香",
  position: "ファイナンシャルプランナー",
  positions: ["ファイナンシャルプランナー"],
  profile:
    "みずほ証券では、20年以上にわたり国内外株、債券、投資信託、保険の販売を通じ、個人・法人顧客向けの資産運用コンサルティング業務に従事。2021年に株式会社OneMile Partners（現：株式会社モニクルフィナンシャル）に入社し、現在は資産運用に役立つコンテンツの発信に注力。CFP（Certified Financial Planner）",
  role: ["moneySeminarSpeaker"],
}

/** @deprecated */
export const morishige: OmpMember = {
  key: "morishige",
  avatarPath: "",
  imagePath: "",
  name: "森重 由里子",
  position: "",
  positions: [],
  profile:
    "ファイナンシャルアドバイザー。学習院女子大学卒業。地方テレビ局でアナウンサー等として従事。出産・子育てを経てオリックス生命保険に入社。個人顧客向け営業やマネージャーなどを経験し、2021年に株式会社OneMile Partners（現：株式会社モニクルフィナンシャル）に入社。現在はマネイロコンシェルとして個人顧客向けに生命保険や投資信託などを活用した資産運用のアドバイスを行う。",
  role: [],
}

export const nishimori: OmpMember = {
  key: "nishimori",
  avatarPath: staticPath_assets_omp_members_nishimori_jpg,
  name: "西森 遥",
  position: "マネイロコンシェル/ファイナンシャルアドバイザー",
  positions: ["マネイロコンシェル", "ファイナンシャルアドバイザー"],
  profile:
    "大手証券会社にて富裕層向けの資産運用アドバイスに従事したのち、マネイロコンシェルに。どんな年齢層のお客様でも、その人に合う資産運用プランを提案できるのが強み。一種外務員資格・保険募集人資格を保有。",
  role: ["moneySeminarSpeaker"],
}

export const shiozawa: OmpMember = {
  key: "shiozawa",
  avatarPath: staticPath_assets_omp_members_shiozawa_jpg,
  name: "塩澤 崇",
  position: "株式会社MFS 取締役 COO/住宅ローンアナリスト",
  positions: ["株式会社MFS 取締役 COO", "住宅ローンアナリスト"],
  profile:
    "モルガン・スタンレー証券、ボストン・コンサルティング・グループを経て、住宅ローンの比較サービス『モゲチェック』を運営する株式会社MFSの取締役COO就任。住宅ローンアナリストとして、多数のメディアにおいて記事執筆、出演中",
  role: ["moneySeminarSpeaker"],
}

export const oono: OmpMember = {
  key: "oono",
  avatarPath: staticPath_assets_omp_members_oono_png,
  name: "大野 貴史",
  position: "ファイナンシャルアドバイザー",
  positions: ["ファイナンシャルアドバイザー"],
  profile:
    "大学卒業後、銀行および生命保険会社を経て株式会社モニクルフィナンシャルに入社。マネイロでは金融商品の調査・分析およびファイナンシャル・アドバイザーの育成を担当。一種外務員（証券外務員一種）、保険募集人、2級ファイナンシャル・プランニング技能士（FP2級）の資格を保有。",
  role: ["moneySeminarSpeaker"],
}

export const ytanaka: OmpMember = {
  key: "ytanaka",
  avatarPath: staticPath_assets_omp_members_ytanaka_png,
  name: "田中 友梨",
  position: "ファイナンシャルアドバイザー",
  positions: ["ファイナンシャルアドバイザー"],
  profile:
    "三井住友銀行を経て2022年にマネイロコンシェルに。銀行時代は銀座エリアの顧客を中心に資産運用コンサルティングに従事。得意分野は老後資金準備相続アドバイス。一種外務員（証券外務員一種）および保険募集人の資格を保有。",
  role: ["moneySeminarSpeaker"],
}

export const ompMembers: OmpMember[] = [
  harada,
  izumida,
  kamura,
  SATO,
  nishimori,
  shiozawa,
  takahashi,
  miyazawa,
  oono,
  ytanaka,
]
