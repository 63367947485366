import { get, Join, PathsToStringProps } from "lib/loda"

/** 文言を一元管理したい
 *
 * - i18nのように
 * - サイズを小さく保つためにテキストデータで完結させる。
 * - ファイルを分けても良いかもしれない。
 * - 良いソリューション求む!
 */
const Texts = {
  compliance: {
    t: "マネイロ 3つのお約束",
    st: "安心してご利用いただくために",
    l1: "お客様からマネイロにお支払いいただく費用は一切ございません。",
    l2: "定期的な見直しや不安になった際も何度でも無料でご相談いただけます。",
    l3: "強引なセールスなどは一切ありません。",
  },
  anshin_shindan: {
    t: "マネイロ 5つの安心",
    st: "安心してご利用いただくために",
    l1: "将来必要な金額を無理なく貯められる投資がわかります！",
    l2: "資産運用の専門家が診断ロジックを設計・監修。",
    l3: "匿名、無料で診断できるので安心です！",
    l4: "より詳しく知りたい時は、お金のプロに無料で聞けます！",
    l5: "強引なセールスなどは一切ありません。",
  },
  anshin_nisa_shindan: {
    t: "マネイロ 5つの安心",
    st: "安心してご利用いただくために",
    l1: "あなたにとって、つみたてNISAの優先度が高いのかを診断できます",
    l2: "世界的金融機関で活躍した資産運用の専門家が診断ロジックを設計しています",
    l3: "匿名、無料で診断できます!",
    l4: "診断データをもとに、より詳しく知りたい時は、お金のプロに無料で聞けます!",
    l5: "強引なセールスなどは一切ありません。",
  },
} as const

/** テキストを取得するgettext風のAPI */
export const __ = (msgID: Join<PathsToStringProps<typeof Texts>, ".">) =>
  get(Texts, msgID)

export default Texts
